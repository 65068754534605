$headings-font-weight: 300;

$enable-responsive-font-sizes: true;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

$fa-font-path: '../../node_modules/fork-awesome/fonts';

$font-family-base: 'Open Sans', $font-family-sans-serif;

$headings-font-family: 'Playfair Display', $font-family-sans-serif;
$btn-font-family: $headings-font-family;

$custom-control-indicator-active-bg: #000;
$custom-control-indicator-checked-bg: #000;
$custom-control-indicator-active-border-color: #000;
$custom-control-indicator-checked-border-color: #000;

$custom-control-indicator-checked-box-shadow:#000;