@import "./variables";
@import "~fork-awesome/scss/fork-awesome.scss";
@import "~bootstrap/scss/bootstrap";

.form-control {
  &::placeholder {
    font-style: italic;
    //font-family: $headings-font-family;

  }
}

html.no-iframe {
  body {
    padding: 1rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

.btn-primary {
  @include button-variant(#333, #333);
}


.btn-secondary {
  @include button-variant(#ccc, #ccc);
}

code {
  color: inherit;
  opacity: .9;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}